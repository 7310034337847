export const appHead = {"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.png"},{"rel":"shortcut-icon","hid":"shortcut-icon","href":"/icon_60.png","sizes":"60x60"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"name":"description","hid":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"name":"og:title","hid":"og:title","content":"Inzhur"},{"name":"og:description","hid":"og:description","content":""},{"name":"og:site_name","hid":"og:site_name","content":"Inzhur"},{"name":"apple-mobile-web-app-title","hid":"apple-mobile-web-app-title","content":"Inzhur"},{"name":"google-site-verification","content":"-I55qhGp_v9bQTz_P8bWJZkREPGARWUSVs17kagGuS4"},{"property":"og:type","content":"website"}],"style":[],"script":[{"src":"/script.js","body":true,"defer":true,"type":"text/javascript","charset":"utf-8"},{"innerHTML":"window.dataLayer = window.dataLayer | [];"},{"hid":"gtm-head","innerHTML":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n            })(window,document,'script','dataLayer','GTM-MB3B44D');\n          ","type":"text/javascript","charset":"utf-8","defer":true}],"noscript":[{"innerHTML":"<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-MB3B44D\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>","tagPosition":"bodyOpen","async":true}],"bodyAttrs":{"class":""},"title":"Inzhur","__dangerouslyDisableSanitizersByTagID":{"gtm-head":["innerHTML"]},"htmlAttrs":{}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = true

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000